import React from "react";
import * as _ from "lodash";
import Kendra from "aws-sdk/clients/kendra";
import {
  Form,
  Modal,
  Button,
  InputGroup,
  Image
} from "react-bootstrap";
import TextHighlights from "./TextWithHighlight";
import "../../search.scss";
import { Relevance } from "../../constants";
import "./modal.css";
import axios from 'axios'
interface ResultTitleProps {
  queryResultItem: Kendra.QueryResultItem;
  attributes: any;

  submitFeedback: (
    relevance: Relevance,
    resultItem: Kendra.QueryResultItem
  ) => Promise<void>;
}

export default class ResultTitle extends React.Component<ResultTitleProps, {}> {
  state = {
    isOpen: false,
    synonym: '',
    // successElement: false,
    // errElement:false
  };
  openModal = () => this.setState({ isOpen: true
    // ,successElement: false,
    // errElement:false 
  });
  closeModal = () => {
    this.setState({ isOpen: false
      // ,successElement: false,
      // errElement:false 
    })
  
  };
  private submitClickFeedback = () => {
    this.openModal();
    this.props.submitFeedback(Relevance.Click, this.props.queryResultItem);
  };

  handleChange = (event: any) => {

    this.setState({ synonym: event.target.value })
  };
  addTerm = async (
    event:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    let DocumentId = this.props.queryResultItem.DocumentId;
    await axios
      .post(
        "https://pheno.cns72.com/create",
        {
          synonym: this.state.synonym,
          DocumentId: DocumentId
        }
      )
      .then((res) => {
        this.closeModal();
        // if (res.data.msg == "success")
        //   this.setState({ successElement: "true" })
      })
      .catch((err) => {
        // console.log(err.response);
        // this.setState({ errElement: "true" })
      });
  };
  render() {
    const { queryResultItem } = this.props;
    var uri;
    // title is not guaranteed to exist, show nothing if that's the case
    let resultTitle: React.ReactNode = null;
    if (
      queryResultItem.DocumentTitle &&
      !_.isEmpty(queryResultItem.DocumentTitle.Text)
    ) {

      resultTitle = (
        <TextHighlights textWithHighlights={queryResultItem.DocumentTitle} />
      );
    } else if (queryResultItem.DocumentURI) {
      resultTitle = queryResultItem.DocumentURI;
    } else {
      return null;
    }

    uri = '?searchTxt=' + queryResultItem.DocumentTitle?.Text;
    if (uri && !_.isEmpty(uri)) {

      resultTitle = (
        <a
          className="action-link"
          href='#'
          onClick={this.submitClickFeedback}
          target="_self"
          rel="noopener noreferrer"
        >
          {resultTitle}
        </a>
      );
    }

    return <><div className="title">{resultTitle}</div><Modal show={this.state.isOpen} onHide={this.closeModal}>
      <Modal.Header closeButton>
        <Modal.Title><span style={{ fontSize: '20px' }}>Enter more information about</span> {resultTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          noValidate
          onSubmit={(event: React.FormEvent<HTMLFormElement>) =>
            this.addTerm(event)
          }
        >
          {/* <input type="text" name="synonym" id="synonym" placeholder="" className="form-control" style={{ width: '600px' }} onChange={this.handleChange} /> */}
          <p><textarea className="form-control" style={{ height: "200px" }} onChange={this.handleChange} /></p>
          <p><input className="form-control" type="submit" name="submit" value="submit" /></p>
        </Form>

      </Modal.Body>
      <Modal.Footer>
        {/* {

          this.state.successElement &&
          <div className="alert alert-success" style={{width:'100%'}}>
            <strong>Success!</strong>&nbsp;&nbsp;&nbsp;New Symptoms has been updated.
           <span style={{color:'black'}}>{resultTitle}</span> 
          </div>
        } */}
        {/* {

      this.state.errElement &&
      <div className="alert alert-danger"  style={{width:'100%'}}>
    <strong>Danger!</strong>&nbsp;&nbsp;&nbsp;New symptoms could not be added.
  </div>
      } */}

      </Modal.Footer>
    </Modal></>;
  }
}
